export const BusinessTypes = {
  FETCH_FEATURED_BUSINESS_PROCESS: 'FETCH_FEATURED_BUSINESS_PROCESS',
  FETCH_FEATURED_BUSINESS_SUCCESS: 'FETCH_FEATURED_BUSINESS_SUCCESS',

  FETCH_SINGLE_BUSINESS_DETAIL_PROCESS: 'FETCH_SINGLE_BUSINESS_DETAIL_PROCESS',
  FETCH_SINGLE_BUSINESS_DETAIL_SUCCESS: 'FETCH_SINGLE_BUSINESS_DETAIL_SUCCESS',
  FETCH_SINGLE_BUSINESS_DETAIL_FAILED: 'FETCH_SINGLE_BUSINESS_DETAIL_FAILED',

  FETCH_BUSINESS_CATEGORIES_PROCESS: 'FETCH_BUSINESS_CATEGORIES_PROCESS',
  FETCH_BUSINESS_CATEGORIES_SUCCESS: 'FETCH_BUSINESS_CATEGORIES_SUCCESS',

  FETCH_ALL_BUSINESSES_PROCESS: 'FETCH_ALL_BUSINESSES_PROCESS',
  FETCH_ALL_BUSINESSES_SUCCESS: 'FETCH_ALL_BUSINESSES_SUCCESS'
};