import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'antd/dist/antd.css';
import './assets/Styles/tailwind.css';
import './assets/Styles/main.css';

import App from './App';
import { store } from './Store/store';
import { createBrowserHistory } from 'history';
import SimpleReactLightbox from 'simple-react-lightbox';

const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <SimpleReactLightbox>
      <App history={history} />
    </SimpleReactLightbox>
  </Provider>,
  document.getElementById('root')
);
