/* eslint-disable max-len */
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import {FiInstagram } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";

import Logo from "../../assets/images/cn-logo-dark.png";

export default function index() {
  return (
    
    <footer className="bg-white  pt-4 pb-8 xl:pt-16 w-full">
      <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8 text-gray-400 dark:text-gray-300">
        <ul className="text-lg font-light pb-8 flex flex-wrap md:flex-nowrap justify-between">
          <li className="w-full md:w-1/3 lg:w-4/5 mb-8">
            <div className="">
              <img className="h-11 " src={Logo} alt="connectNigeria Logo"/>
              <h2 className="mt-6 text-base font-medium mb-4">
                <span className="text-mainLightGreen-100">Need it, Search it, </span> <span className="text-mainOrange-100"> Find it</span> 
              </h2>
             
            </div>
          </li>
          <li className="w-1/2 md:w-1/3 lg:w-1/5">
            <div className="text-base text-mainBlack-100 font-normal">
              <h2 className="text-mainBlack-100 text-xs font-bold uppercase mb-4">
              BROWSE ConnectNigeria
              </h2>
              <ul>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BUSINESSES_URL}`}>
                  Businesses
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_CARS_URL}`}>
                  Cars
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_DEALS_URL}`}>
                  Deals                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_EVENTS_URL}`}>
                  Events                 </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_REAL_ESTATES_URL}`}>
                  Real Estate
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_JOBS_URL}`}>
                  Jobs
                  </a>
                </li>
                {/* <li className="mb-4  transition-colors duration-200">
                  <a href="#">
                  Phones
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href="#">
                  Electronics
                  </a>
                </li> */}
                
              </ul>
            </div>
          </li>
          <li className="w-1/2 md:w-1/3 lg:w-1/5">
            <div className="text-base text-mainBlack-100 font-normal">
              <h2 className="text-mainBlack-100 text-xs font-bold uppercase mb-4">
              LEARN MORE
              </h2>
              <ul>
                <li className="mb-4  transition-colors duration-200">
                  <a  href={`${process.env.REACT_APP_CN_BASE_URL}/about-us`}>
                  About
                  </a>
                  
                  
                 
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BASE_URL}/contact-us`}>
                  Contact Us
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BASE_URL}/frequently-asked-questions`}>
                 FAQ&#39;s            </a>

                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BASE_URL}/terms-and-conditions`}>
                  Terms Of Use                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BASE_URL}/privacy-policy`}>
                  Privacy Policy                  </a>
                </li>
                {/* <li className="mb-4  transition-colors duration-200">
                  <a href="#">
                  Disclaimer
                  </a>
                </li> */}
                
                
              </ul>
            </div>
          </li>
          <li className=" w-1/2 md:w-1/3 lg:w-1/5">
            <div className="text-base text-mainBlack-100 font-normal">
              <h2 className="text-mainBlack-100 text-xs font-bold uppercase mb-4">
              STAY UPDATED             </h2>
              <ul>
                <li className="mb-4  transition-colors duration-200">
                  <a href={`${process.env.REACT_APP_CN_BASE_URL}/advertise`} target="_blank" rel="noreferrer">
                  Advertise
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href="https://articles.connectnigeria.com/" target="_blank" rel="noreferrer">
                  Articles                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href="https://cntv.connectnigeria.com/" target="_blank" rel="noreferrer">
                  CNTV
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href="https://www.connectnigeria.com/bizmix" target="_blank" rel="noreferrer">
                  Business Mixer
                  </a>
                </li>
                <li className="mb-4  transition-colors duration-200">
                  <a href="https://club.connectnigeria.com/" target="_blank" rel="noreferrer">
                  Club Connect
                  </a>
                </li>
                
                
              </ul>
            </div>
          </li>
        </ul>
        <div className="py-4 lg:flex border-t border-gray-200  mx-auto items-center justify-between">
          <div className="text-mainBlack-80 text-xs text-center  lg:text-base font-normal flex items-center justify-center">
          © {new Date().getFullYear()} ConnectNigeria.com — All Rights Reserved
          </div>
          <div className="flex justify-center mt-5 lg:mt-0 lg:justify-between lg:w-48  max-w-xs items-center ">
            <a href="https://web.facebook.com/ConnectNigeria/" target="_blank" rel="noreferrer" className="ml-4">
              {/* <BsTwitter className="text-lg text-mainBlack-60 transition-colors duration-200"/> */}
              <FaFacebookF className="text-lg text-mainBlack-60 transition-colors duration-200" />
            
            </a>
            <a href="https://www.linkedin.com/company/connectnigeria.com/" target="_blank" rel="noreferrer" className="ml-4">
              <BsLinkedin className="text-lg text-mainBlack-60 transition-colors duration-200"/>
             
            </a>
            <a href="https://twitter.com/ConnectNigeria" target="_blank" rel="noreferrer" className="ml-4">
              <BsTwitter className="text-lg text-mainBlack-60 transition-colors duration-200"/>

            </a>
            <a href="https://www.instagram.com/connectnigeria/" target="_blank" rel="noreferrer" className="ml-4">
              <FiInstagram  className="text-lg text-mainBlack-60 transition-colors duration-200"/>
              
            </a>
            
          </div>
          
        </div>
       
      </div>
    </footer>

  );
}
