// import { Router } from 'react-router-dom';
import { renderRoutes, routes } from './routes/routes';
import {  Router } from "react-router-dom";
import { AuthStateCheck } from './Global/AuthStateCheck';
import Notify from './Global/Notify';

function App({ history }) {
  return ( 
    <Router history={history}> 
      <Notify>
        <AuthStateCheck>
          { renderRoutes(routes) } 
        </AuthStateCheck>
      </Notify>
    </Router>
  );
}
export default App;